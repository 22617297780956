import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class AlertService {
    constructor(
        private _toastr: ToastrService,
        private _translateService: TranslateService
    ) {}

    async showSuccess(messageKey: string, titleKey?: string, params?: any, alertConfig?: any): Promise<boolean> {
        return this._computeTranslations(
            (translations) => {
                this._toastr.success(translations[0], translations[1]);
            },
            messageKey,
            titleKey,
            params
        );
    }

    async showInfo(messageKey: string, titleKey?: string, params?: any, alertConfig?: any): Promise<boolean> {
        return this._computeTranslations(
            (translations) => {
                this._toastr.info(translations[0], translations[1], params);
            },
            messageKey,
            titleKey,
            params
        );
    }

    async showWarning(messageKey: string, titleKey?: string, params?: any, alertConfig?: any): Promise<boolean> {
        return this._computeTranslations(
            (translations) => {
                this._toastr.warning(translations[0], translations[1], params);
            },
            messageKey,
            titleKey,
            params
        );
    }

    async showError(messageKey: string, titleKey?: string, params?: any, alertConfig?: any): Promise<boolean> {
        return this._computeTranslations(
            (translations) => {
                this._toastr.error(translations[0], translations[1], params);
            },
            messageKey,
            titleKey,
            params
        );
    }

    private _computeTranslations(toastFn: (t) => any, messageKey: string, titleKey?: string, params?: any): Promise<boolean> {
        return Promise.all([
            messageKey ? this._translateService.get(messageKey, params).toPromise() : undefined,
            titleKey ? this._translateService.get(titleKey, params).toPromise() : undefined
        ])
            .then((translations) => {
                toastFn(translations);
                return true;
            })
            .catch((err) => {
                console.log(`[AlertService]: There was an error: ${err}`);
                return false;
            });
    }
}
